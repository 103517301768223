<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header stemps">
      <a class="icon iconButton third" @click.prevent="$router.push('/home')">
        <font-awesome-icon
          icon="fa-solid fa-chevron-left"
          size="lg"
        ></font-awesome-icon>
      </a>
      <span class="fL">我的集章</span>
      <div class="icon iconButton"></div>
    </section>
    <!-- Main -->
    <section class="main stemps">
      <!-- Filter -->
      <div class="filter flexH width">
        <div class="flexH width middle">
          <div class="dropdown rounded">
            <select name="type" class="fM rounded" v-model="sortBy" @change="toSort()">
              <option value="0">排序</option>
              <option value="1">來店獲章</option>
              <option value="2">集章期限</option>
              <option value="3">兌換期限</option>
              <option value="4">加入票夾時間</option>
            </select>
            <div class="arrow third">
              <font-awesome-icon
                icon="fas fa-caret-down"
                size="xs"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>
      <!-- List -->
      <div class="list flexV width">
        <!-- 搜尋 -->
        <div class="input rounded">
          <input type="text" class="fM" placeholder="搜尋" v-model="searchStr" @keypress.enter="toSearch()" />
          <a class="icon iconButton" @click.prevent="toSearch()"
            ><font-awesome-icon
              icon="fas fa-search"
              size="lg"
            ></font-awesome-icon
          ></a>
        </div>
        <!-- 集章列表 -->
        <a
          class="stampsItem flexV around"
          v-for="(stampCard, index) in displayStampCards"
          :key="'stampCard' + index"
          @click.prevent="$router.push(`/stamp-cards/${stampCard.stampCardId}`)"
        >
          <span class="fL third single">{{ stampCard.stampCardName }}</span>
          <span class="fM single">{{ stampCard.storeName }}</span>
          <span class="fS gray"
            >集章 {{ tsToDate(stampCard.collectStartTime) }} -
            {{ tsToDate(stampCard.collectEndTime) }}</span
          >
          <span class="fS gray"
            >兌換 {{ tsToDate(stampCard.redeemStartTime) }} -
            {{ tsToDate(stampCard.redeemEndTime) }}</span
          >
          <div class="flexH width">
            <img src="@/assets/img/stamp.png" />
            <div class="bar flexH width rounded">
              <div :style="{ width: calProgress(stampCard) + '%' }"></div>
            </div>
            <span class="fM bold primary"
              >{{ stampCard.numOfStamps }}/{{
                stampCard.stampCardMaxStamps
              }}</span
            >
          </div>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  name: "MyStampCards",
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      stampCards: [],
      searchStr: "",
      sortBy: "0",
      displayStampCards: []
    };
  },
  mounted() {
    this.getStampCards();
  },
  computed: {
    ...mapState(["user", "userInfo"]),
  },
  methods: {
    dummyStampCards() {
      let result = [
        {
          numOfStamps: 8, //現有集章數
          collectedAt: 1653807421, //加入票夾時間（前端排序用）
          updatedAt: 1653807421, //最近獲章時間（前端排序用）
          storeId: 1,
          storeName: "美又美早餐店桃園店", //特店名稱
          storeCategoryId: 1,
          storeAddress: "桃園市桃園區縣府路1號",
          storePhone: null,
          stampCardId: "WJZbm9bgEv",
          stampCardName: "集點卡", //集章標題
          stampCardDesc: "集滿十點，下次消費九五折",
          status: "normal",
          bannerImageUrl:
            "https://pixabay.com/get/g640dec5cd6634a846e3d65f7fe7badba7837d241a311fc0d6d3394dd083aac5e1dccfee71a34851c80c16055fa937565b56836074c2b4f060319b88be396e58def0844b20a32784698ec1bc0534efa68_640.jpg",
          stampCardMaxStamps: 20, //集章數上限
          collectStartTime: 1653235200, //集章開始時間
          collectEndTime: 1656604499, //集章截止時間（前端排序用）
          redeemStartTime: 1654444800, //兌換開始時間
          redeemEndTime: 1658073599, //兌換截止時間（前端排序用）
          createdAt: 1653806706,
        }, {
          numOfStamps: 18, //現有集章數
          collectedAt: 1653807521, //加入票夾時間（前端排序用）
          updatedAt: 1653807521, //最近獲章時間（前端排序用）
          storeId: 1,
          storeName: "美又美早餐店桃園店2", //特店名稱
          storeCategoryId: 1,
          storeAddress: "桃園市桃園區縣府路1號2",
          storePhone: null,
          stampCardId: "WJZbm9bgEv",
          stampCardName: "集點卡2", //集章標題
          stampCardDesc: "集滿十點，下次消費九五折2",
          status: "normal",
          bannerImageUrl:
            "https://pixabay.com/get/g640dec5cd6634a846e3d65f7fe7badba7837d241a311fc0d6d3394dd083aac5e1dccfee71a34851c80c16055fa937565b56836074c2b4f060319b88be396e58def0844b20a32784698ec1bc0534efa68_640.jpg",
          stampCardMaxStamps: 20, //集章數上限
          collectStartTime: 1653235300, //集章開始時間
          collectEndTime: 1656604599, //集章截止時間（前端排序用）
          redeemStartTime: 1654444900, //兌換開始時間
          redeemEndTime: 1658073699, //兌換截止時間（前端排序用）
          createdAt: 1653806806,
        },
      ];
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callGetMyStampCardsAPI(keyword) {
      let url = `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards?uuid=${this.user.userId}`;
      if (keyword && keyword.length)
        url += `&search=${keyword}`;
      var config = {
        method: "get",
        url: url,
        headers: {},
      };
      return this.$http(config);
    },
    getStampCards(keyword="") {
      this.callGetMyStampCardsAPI(keyword)
      // return this.dummyStampCards(keyword)
        .then((res) => {
          console.log("getStampCards res: ", res);
          this.stampCards = res.data;
          this.toSort();
          return;
        })
        .catch((err) => console.log(err));
    },
    tsToDate(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD");
    },
    calProgress(stampCard) {
      if (stampCard.collectedAt)
        return (stampCard.numOfStamps / stampCard.stampCardMaxStamps) * 100;
      return 0;
    },
    async toSearch() {
        await this.getStampCards(this.searchStr);
        this.toSort();
    },
    sortByLastGainStampTimeDesc(b, a) {
        return a.updatedAt - b.updatedAt;
    },
    sortByEndOfCollectTimeDesc(b, a) {
        return a.collectEndTime - b.collectEndTime;
    },
    sortByEndOfRedeemTimeDesc(b, a) {
        return a.redeemEndTime - b.redeemEndTime;
    },
    sortByCollectAtDesc(b, a) {
        return a.collectedAt - b.collectedAt;
    },
    toSort() {
        let sortBy = this.sortBy;
        let originStampCards = this.stampCards;
        this.displayStampCards = JSON.parse(JSON.stringify(originStampCards));

        switch (sortBy) {
            case "0":
                this.displayStampCards = JSON.parse(JSON.stringify(originStampCards));
                break;
            case "1":
                this.displayStampCards.sort(this.sortByLastGainStampTimeDesc);
                break;
            case "2":
                this.displayStampCards.sort(this.sortByEndOfCollectTimeDesc);
                break;
            case "3":
                this.displayStampCards.sort(this.sortByEndOfRedeemTimeDesc);
                break;
            case "4":
                this.displayStampCards.sort(this.sortByCollectAtDesc);
                break;
            default:
                this.displayStampCards = JSON.parse(JSON.stringify(originStampCards));
                break;
        }
    }
  },
};
</script>
